
import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import KeyIcon from '@mui/icons-material/Key';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GoogleIcon from '@mui/icons-material/Google';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#ff3d00',
    },
    neutral: {
      main: '#ff3d00',
      contrastText: '#fff',
    },
  },
});
//const paperStyle = { padding: 20, height: '80vh', width: 380, margin: "40px auto", borderRadius: '1em 1em 1em 1em' }

export default function SignInBox() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
<ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
   <Box
   sx={{
     marginTop: 4,
     display: 'flex',
     flexDirection: 'column',
     alignItems: 'center',
   }}
 >

   <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
     <KeyIcon />
   </Avatar>
   <Typography component="h1" variant="h5">
     Sign in
   </Typography>
   <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
     <TextField
       margin="normal"
       required
       fullWidth
       id="email"
       label="Email Address"
       name="email"
       autoComplete="email"
       autoFocus
     />
     <TextField
       margin="normal"
       required
       fullWidth
       name="password"
       label="Password"
       type="password"
       id="password"
       autoComplete="current-password"
     />
     <FormControlLabel
       control={<Checkbox value="remember" color="primary" />}
       label="Remember me"
     />
     <Button
       type="submit"
       fullWidth
       variant="contained"
       sx={{ mt: 3, mb: 2 }}
     >
       Sign In
     </Button>
     <Grid container>
       <Grid item xs>
         <Link href="#" variant="body2">
           Use OTP to Login
         </Link>
       </Grid>
       <Button
         type="submit"
         color='neutral'
         fullWidth
         variant="outlined"
         startIcon={<GoogleIcon />}
         sx={{ mt: 3, mb: 2, borderRadius: 28 }  }
       >
         Sign in with Google
       </Button>

     </Grid>

   </Box>

 </Box>
 <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}