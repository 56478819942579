
//import SignIn from './components/SignIn'
import { useContext,useState } from 'react';
import Meals from './components/Meals/Meals';
import PersistentDrawerLeft from './components/UI/PersistentDrawerLeft';
import CartContext from './components/Store/CartContext';
import CartContextProvider from './components/Store/CartContextProvider';


function App() {
const cardCtx = useContext(CartContext);
const [user, setUser] = useState(9);

  return (
    <CartContextProvider>
        <PersistentDrawerLeft/>
        <Meals />
   
        </CartContextProvider>
  );
}

export default App;

