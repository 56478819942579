import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Grid } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Dialog, Chip } from '@mui/material';

import logo from './logo.png';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Badge from '@mui/material/Badge';
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import SignInBox from './SignInBox';
import { useContext } from 'react';
import CartContext from '../Store/CartContext';


const drawerWidth = 250;




const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [cartClick, setCartClick] = React.useState(false);

  //const [cartCount, setCartCount] = useState(0);
  //const [openLoginPage, setOpenLoginPage] = React.useState(false);
  const imm = Math.random();
  const im = 'https://source.unsplash.com/1280x720/?food/?sig=' + imm;
  const cartCtx = useContext(CartContext);
  const { items } = cartCtx;
  console.log("GOGO", items)
  console.log("Gaurav", cartCtx.totalAmount);

  const numberOfCartItems = items.reduce((curNumber, item) => {
    return curNumber + item.amount;
  }, 0);


  const handleQuantityAdd = item => {

    cartCtx.addItem({ ...item });
  };

  const handleQuantitySub = id => {

    cartCtx.removeItem(id);
  };



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleDrawerCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleCartClickShow = () => {
    setCartClick(true);
  };

  const handleCartClickHide = () => {
    setCartClick(false);
  };


  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));
  return (


    <Box sx={{ flexDirection: 'column'}}
      padding='0'
      height={20}
      direction="Row-reverse"
     
    >
      <CssBaseline />


      <AppBar position="fixed" open={open} openLogin={openLogin}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            sx={{
              height: 40,
            }}
            alt="Your logo."
            src={logo}
          />
          <Typography variant="h5" component="div" sx={{ flexGrow: 2 }}>
            OrderFood
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpenLogin}
            sx={{ ...(openLogin && { display: 'none' }) }}
          >
            Login
            <LoginSharpIcon />
          </IconButton>


          <IconButton aria-label="cart" onClick={handleCartClickShow}>




            <StyledBadge badgeContent={numberOfCartItems} color="secondary">
              <ShoppingCartSharpIcon />
            </StyledBadge>



          </IconButton>
          <Dialog 
            open={cartClick}
            onClose={handleCartClickHide}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{bgcolor: 'background.paper' }} >
              <Grid sx={{ justifyContent: 'flex-end' }}>


                {
                  cartCtx.items.map((item) => (

                    <Grid marginLeft={3} marginTop={3} divider sx={{width: { xs: 300, md: 500 }}}


                    >

                      <Typography sx={{ justifyContent: 'flex-Start' }} gutterBottom variant="h5" component="div">{item.name}</Typography>

                      <img alt="Your Food" height={100} width={200} component="img" src={im} />

                      <Typography sx={{ justifyContent: 'flex-end' }} gutterBottom variant="h8" component="div">${item.price}</Typography>






                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                        <Button size="small" onClick={handleQuantitySub.bind(null, item.id)}>-</Button>
                        <Chip label={item.amount} variant="outlined" />
                        <Button size="small" onClick={handleQuantityAdd.bind(null, item)}>+</Button>

                      </Box>

                      <Divider />

                    </Grid>



                  )

                  )
                }



              </Grid>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                <Button justifyContent='right' onClick={handleCartClickHide}>Cancel</Button>
                <Button justifyContent='right'  >Order</Button>
              </Box>
              <h3 sx={{ justifyContent: 'flex-end' }} variant="outlined">Total:{cartCtx.totalAmount}</h3>

            </Box>
          </Dialog>

        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Login', 'FoodApp', 'Form', 'Email'].map((text, index) => (
            <ListItem button key={text}


            >
              <ListItemIcon>
                {<ArrowRightAltIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />

      </Drawer>

      <Drawer
        sx={{
          width: 400,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 400,
          },
        }}
        variant="persistent"
        anchor="right"
        open={openLogin}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerCloseLogin}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <SignInBox />
        </DrawerHeader>

      </Drawer>

      <Main open={open}>
        <DrawerHeader />
      </Main>

    </Box>


  );
}
