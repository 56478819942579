import Grid from '@mui/material/Grid';
import MealItem from './MealItem/MealsItem';
const AvailableMeals = () => {
    const data = {
        DUMMY_MEALS: [
            {
                id: 'm1',
                name: 'Sushi',
                description: 'Finest fish and veggies',
                price: 22,
            },
            {
                id: 'm2',
                name: 'Schnitzel',
                description: 'A german specialty!',
                price: 16,
            },
            {
                id: 'm3',
                name: 'Barb Burger',
                description: 'American, raw, meaty',
                price: 12,
            },
            {
                id: 'm4',
                name: 'Green Bowl',
                description: 'Healthy...and green...',
                price: 18,
            },

        ],
        rowid: [1,2,3,4

        ]
    };



    return (
        <div>
            {


                data.rowid.map((r) => (
                    <Grid
                        container
                        spacing={2}
                        
                        marginTop={1}
                        marginLeft={0}
                        key={r}
                        sx={{flexDirection: { xs: 'column', md: 'row' }}}


                    >
                        {
                            data.DUMMY_MEALS.map((elem) => (
                                <Grid spacing={2} item xs={12} sm={3} key={data.DUMMY_MEALS.indexOf(elem)}


                                >
                                    <MealItem
                                        key={elem.id}
                                        id={elem.id}
                                        name={elem.name}
                                        description={elem.description}
                                        price={elem.price}
                                        amount={0}
                                    >

                                    </MealItem>
                                </Grid>
                            ))
                        }
                    </Grid>
                )
                )

            }
        </div>

    );
}


export default AvailableMeals;