import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardMedia } from '@mui/material';

import Typography from '@mui/material/Typography';





export default function MealsSummary(props) {
  const imm = Math.random();
  const im = 'https://source.unsplash.com/1280x720/?food/?sig=' + imm

  return (

    <Card 
    
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'column' },
      alignItems: 'center',
      bgcolor: 'background.paper',
      overflow: 'hidden',
      fontWeight: 'bold'

    }}

    
    >
         <CardMedia
        component="img"
        height="300"
        width="100"
        src={im}


      />
      <CardContent alignItems="center"
        justify="center">
        <Typography  align='center' sx={{
      display: 'flex',}} padding="normal" variant="h4" component="div">
        Delicious Food, Delivered To You
        </Typography>
        <Typography    sx={{
      display: 'flex',}} variant="h6" color="text.primary">
        Choose your favorite meal from our broad selection of available meals
        and enjoy a delicious lunch or dinner at home.
        </Typography>
        <Typography variant="h6" color="text.primary">
        All our meals are cooked with high-quality ingredients, just-in-time and
        of course by experienced chefs!
        </Typography>
      </CardContent>

    </Card>



  );
}
