
import { Card, CardMedia,CardContent,Typography,CardActions } from "@mui/material";
import { useContext } from 'react';
import MealItemForm from './MealItemForm';
import CartContext from "../../Store/CartContext";



const MealItem = (props) => {

  const price = `$${props.price.toFixed(2)}`;
  
  
  const imm = Math.random();
  const im = 'https://source.unsplash.com/1280x720/?food/?sig=' + imm;

  const cartCtx = useContext(CartContext);

  const addToCartHandler = amount => {

    cartCtx.addItem({
      id: props.id,
      name: props.name,
      amount: amount,
      price: props.price
    });
  };

  const removeItemFromCartHandler = id => {

    cartCtx.removeItem(id);
  };

  const { items } = cartCtx;

  const numberOfCartItems = items.reduce((curNumber, item) => {
    return curNumber + item.amount;
  }, 0);
  return (


    <Card
    sx={{ maxWidth: 345 }}
    >
      <CardMedia
       
        component="img"
        height="140"
        src={im}
      />
    <CardContent>
    <Typography gutterBottom variant="h5" component="div">{props.name}</Typography>
    <Typography variant="body2" color="text.secondary"> {props.description}</Typography>
    <Typography variant="h7" gutterBottom component="div">{price}</Typography>
    </CardContent>
    <CardActions>
      <MealItemForm key={props.key}
        id={props.id}
        onAddToCart={addToCartHandler} onRmvToCart={removeItemFromCartHandler} amountFromCtx={numberOfCartItems} />
    
    </CardActions>


    </Card>








  );
};

export default MealItem;