import { Chip, Button } from '@mui/material';
import { useEffect, useState,useContext} from 'react'
import CartContext from '../../Store/CartContext';



const MealItemForm = (props) => {

 
  const [quantity,setQuantity] = useState(0);
  const [a,setA] = useState(false);
  const [s,setS] = useState(false);

  


  const cartCtx= useContext(CartContext);

  useEffect(() =>
    {
      if (a) { props.onAddToCart(quantity); };
      
      if (s) {props.onRmvToCart(props.id);};
      
      setS(false);
      setA(false);
    

    },[quantity]

  );

  

  const handleQuantitySub =()=>{
   
 
    if (quantity >= 1 && quantity < 6 ) {
      setQuantity((quantity) => quantity-1);
      setS(true);
      
    }

  };
   

 
  const handleQuantityAdd = ()=> {
   
   
    if (quantity >= 0 && quantity < 5) {
      setQuantity((quantity) => quantity+1);
      setA(true);
   
    }
  };
  var amt;
   
  cartCtx.items.map(itm => {
    if ( itm.id === props.id) {

      amt = itm.amount

    }
   
});
  

  return (

    <>
      <Button size="small" onClick={handleQuantitySub} >-</Button>
      <Chip label={amt} variant="outlined"/>  
      <Button  size="small"  onClick={handleQuantityAdd}>+</Button>
    </ >
  );
};

export default MealItemForm;